* {
  font-family: "Roc Grotesk";
}

.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  opacity: 0;
  transition: opacity 0s;
  overflow: hidden;
}

.home-container.loaded {
  opacity: 1 !important;
}

.home-text01 {
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  display: inline-block;
  position: relative;
}

.home-text01:hover {
  color: #51B46B;
}

.home-banner {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

.home-text07 {
  font-size: 3rem;
  font-style: normal;
  text-align: center;
  font-weight: 400;
  white-space: pre-wrap;
}

.home-text13 {
  max-width: var(--dl-size-size-maxwidth);
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
  max-width: 800px;
}

.home-btn-group {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.home-button {
  color: #FFFFFF;
  border: none;
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: #51B46B;
  display: inline-block;
}

.home-button:hover {
  transform: scale(1.02);
  filter: brightness(90%);
}

.home-button1 {
  color: black;
  border: black 1px solid;
  transition: 0.3s;
  margin-left: var(--dl-space-space-unit);
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: transparent;
  display: inline-block;
}

.home-button1:hover {
  transform: scale(1.02);
}


.mobile-burger-menu {
  display: none;
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
}

.mobile-icon {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
}

.mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}

.mobile-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.mobile-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}

.mobile-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-icon02 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}

.mobile-links1 {
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.mobile-text {
  margin-bottom: var(--dl-space-space-unit);
}

.mobile-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}

.mobile-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}

.mobile-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}

.home-image {
  width: 100%;
  max-height: 547px;
  object-fit: cover;
}

.home-who-we-are {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home-container01 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-text16 {
  width: fit-content;
  height: fit-content;
  font-size: 70px;
  margin-left: var(--dl-space-space-sixunits);
  margin-bottom: var(--dl-space-space-threeunits);
}

.home-text17 {
  display: none;
}

.home-button2 {
  border-radius: 30px;
  display: inline-block;
  margin-left: var(--dl-space-space-sixunits);
}

.home-button2:hover {
  transform: scale(1.02);
}

.home-text20 {
  width: auto;
  display: block;
  text-align: left;
  letter-spacing: 0.1px;
  font-size: 18px;
  line-height: 28px;
  max-width: 850px;
  margin: 2rem;
}

.home-investment-criteria {
  width:fit-content;
  height: fit-content;
  display: flex;
  position: relative;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 3rem;
  transition: 0.1s height linear;
}

.home-text24 {
  font-size: 60px;
  flex: 1;
}

.home-container02 {
  width: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: fit-content;
  transition: 0.1s height linear;
}

.separator {
  width: 100%;
  height: 1px;
  margin-top: var(--dl-space-space-fourunits);
  background-color: var(--dl-color-gray-900);
}

.home-container04 {
  flex: 0 0 auto;
  width: 1175px;
  height: 100px;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
  margin-right: var(--dl-space-space-twounits);
  justify-content: space-between;
}

.home-container05 {
  flex: 0 0 auto;
  width: 704px;
  height: 71px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.home-text25,
.home-text26 {
  font-size: 60px;
}

.home-container07 {
  flex: 0 0 auto;
  width: 1175px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home-container08 {
  flex: 0 0 auto;
  width: 431px;
  height: 71px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.team-exp-container,
.stage-container,
.industry-container {
  flex: 0 0 auto;
  width: 1175px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 1s ease-in-out all;
  padding: 50px;
}


.arrow {
  font-size: 60px;
  transition: 0.3s linear;
}

.home-container11 {
  flex: 0 0 auto;
  width: 758px;
  height: 71px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.home-text28,
.home-text29,
.home-text31,
.home-text32 {
  font-size: 60px;
  transition: 0.2s ease-in-out all;
}

.team-exp-text,
.stage-text,
.industry-text {
  display: flex;
  width: 589px;
  max-height: 0;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  transition: opacity 0.1s linear, max-height 0.1s linear;
  margin-left: 1rem;
  opacity: 0;
}

.home-text33 {
  font-size: 16px;
  margin-left: var(--dl-space-space-unit);
  
}

.home-our-investments {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* Aligns contents to the left */
  justify-content: flex-start;
  /* Aligns contents to the top */
  background-color: #51B46B;
  padding-bottom: 3rem;
}

.home-text35 {
  color: #FFFFFF;
  font-size: 60px;
}

.home-text36 {
  color: #ffffff;
  margin-top: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-twounits);
  max-width: 620px;
}

/* .home-container15 {
  width: auto;
  max-width: 1175px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: var(--dl-space-space-fourunits);
} */

.home-container17 {
  flex: 0 0 auto;
  width: 1175px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home-text40 {
  color: #FFFFFF;
  font-size: 60px;
}

.home-text41 {
  color: #FFFFFF;
  font-size: 60px;
}

.home-container20 {
  flex: 0 0 auto;
  width: 1175px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home-text42 {
  color: #FFFFFF;
  font-size: 60px;
}

.home-text43 {
  color: #FFFFFF;
  font-size: 60px;
}

.invest-separator {
  height: 1px;
  margin-top: var(--dl-space-space-twounits);
  background-color: var(--dl-color-gray-900);
  width: 100%;
}

.home-container23 {
  flex: 0 0 auto;
  width: 1175px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home-text44 {
  color: #FFFFFF;
  font-size: 60px;
}

.home-text45 {
  color: #FFFFFF;
  font-size: 60px;
}

.home-how-we-add-value {
  width: 100%;
  background-color: #51B46B;
  padding-bottom: 5rem;
  padding-top: 3rem;
  height: auto;
}

.home-container15 {
  width: auto;
  max-width: 1175px;
  height: 837px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  margin-left: auto;
  margin-right: auto;
}

.home-container25 {
  flex: 0 0 auto;
  width: 1175px;
  height: 100px;
  display: flex;
  padding-top: var(--dl-space-space-twounits);
  align-items: center;
  justify-content: space-between;
}

.home-container26 {
  flex: 0 0 auto;
  width: 769px;
  height: 71px;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-fourunits);
  justify-content: space-between;
}

.home-text46 {
  color: var(--dl-color-gray-black);
  font-size: 60px;
  margin-left: var(--dl-space-space-unit);
  max-width: 370px;
  color: #FFFFFF;
}

.home-text50 {
  color: var(--dl-color-gray-black);
  font-size: 16px;
  margin-top: var(--dl-space-space-halfunit);
  text-align: left;
  color: #FFFFFF;
}

.home-container27 {
  flex: 0 0 auto;
  width: 1175px;
  height: 100px;
  display: flex;
  padding-top: var(--dl-space-space-twounits);
  align-items: center;
  justify-content: space-between;
}

.home-container28 {
  flex: 0 0 auto;
  width: 769px;
  height: 71px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.home-text51 {
  color: var(--dl-color-gray-black);
  font-size: 50px;
  margin-left: var(--dl-space-space-unit);
  color: #FFFFFF;
}

.home-text54 {
  color: var(--dl-color-gray-black);
  font-size: 16px;
  margin-top: var(--dl-space-space-halfunit);
  text-align: left;
  color: #FFFFFF;
}

.home-container29 {
  flex: 0 0 auto;
  width: 1175px;
  height: 100px;
  display: flex;
  padding-top: var(--dl-space-space-twounits);
  align-items: center;
  justify-content: space-between;
}

.home-container30 {
  flex: 0 0 auto;
  width: 769px;
  height: 71px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.home-text55 {
  color: var(--dl-color-gray-black);
  font-size: 50px;
  margin-left: var(--dl-space-space-unit);
  color: #FFFFFF;
}

.home-text58 {
  color: var(--dl-color-gray-black);
  font-size: 16px;
  margin-top: var(--dl-space-space-halfunit);
  text-align: left;
  color: #FFFFFF;
}

.home-container31 {
  flex: 0 0 auto;
  width: 1175px;
  height: 100px;
  display: flex;
  padding-top: var(--dl-space-space-twounits);
  align-items: center;
  justify-content: space-between;
}

.home-container32 {
  flex: 0 0 auto;
  width: 769px;
  height: 71px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.home-text59 {
  color: var(--dl-color-gray-black);
  font-size: 50px;
  margin-left: var(--dl-space-space-unit);
  color: #FFFFFF;
}

.home-text62 {
  color: var(--dl-color-gray-black);
  font-size: 16px;
  margin-top: var(--dl-space-space-halfunit);
  text-align: left;
  color: #FFFFFF;
}

.subscribe {
  color: #fff;
  margin-left: 1rem;
}

sup {
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  color: #FFFFFF;
}

@media(min-width: 1700px) {
  .home-footer {
    flex-direction: column;
  }

  .home-product-container {
    margin-right: var(--dl-space-space-fourunits);
  }

  /* .separator {
    width: 100%;
  }
  .invest-separator {
    width: 80%;
  } */
}

@media(min-width: 1200px) and (max-width: 1699px) {
  .home-banner {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }

  .home-text13 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }

  .home-footer {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }

  .home-container34 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }

  .home-logo1 {
    align-items: center;
  }

  .home-text71 {
    margin-top: var(--dl-space-space-twounits);
    text-align: center;
    margin-bottom: var(--dl-space-space-twounits);
  }

  .home-links-container {
    width: 100%;
    align-items: flex-start;
    flex-direction: row;
    justify-content: center;
  }

  .home-product-container {
    margin-right: var(--dl-space-space-fourunits);
  }

  /* .separator {
    width: 80%;
  }
  .invest-separator {
    width: 80%;
  } */
}

@media(min-width: 1001px) and (max-width: 1199px) {
  .home-banner {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }

  .home-text13 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }

  .home-footer {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }

  .home-container34 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }

  .home-logo1 {
    align-items: center;
  }

  .home-text71 {
    margin-top: var(--dl-space-space-twounits);
    text-align: center;
    margin-bottom: var(--dl-space-space-twounits);
  }

  .home-links-container {
    width: 100%;
    align-items: flex-start;
    flex-direction: row;
    justify-content: center;
  }

  .home-container35 {
    margin-right: var(--dl-space-space-fourunits);
  }

  .home-product-container {
    margin-right: var(--dl-space-space-fourunits);
  }

  /* .separator {
    width: 90%;
  }
  .invest-separator {
    width: 90%;
  } */
}

@media(max-width: 1000px) {
  .home-banner {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }

  .home-btn-group {
    flex-direction: row;
    justify-content: space-between;
  }

  .home-button {
    margin-top: var(--dl-space-space-unit);
    margin-left: 0px;
    width: fit-content;
    margin-right: 10px;
    font-size: 0.8rem;
  }

  .home-button1 {
    margin-top: var(--dl-space-space-unit);
    margin-left: 0px;
    width: fit-content;
    font-size: 0.8rem;
  }

  .mobile-burger-menu {
    display: flex;
  }

  .desktop-menu {
    display: none;
  }

  .home-text07 {
    text-align: left;
    font-size: 2rem;
    display: inline;
  }

  .home-text13 {
    text-align: left;
  }

  .home-container01 {
    align-items: normal;
  }

  .home-text16 {
    font-size: 2rem;
    /* margin-top: var(--dl-space-space-unit); */
    margin-left: 0px;
    height: fit-content;
    padding-left: 1rem;
    margin-bottom: 0;
  }
  
  .home-who-we-are {
    height: fit-content;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .home-text20 {
    display: none;
  }

  .home-text17 {
    display: inline-block;
    text-align: left;
    word-wrap: break-word;
    max-width: 400px;
    margin-bottom: 2rem;
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .home-button2 {
    width: 50%;
    margin-left: 1rem;
  }

  .team-exp-container:hover,
  .stage-container:hover,
  .industry-container:hover {
    transform: none;
  }

  .home-investment-criteria {
    width: 100%;
    align-items: flex-start;
    padding-top: 2rem;
  }

  .home-text24 {
    font-size: 2rem;
    padding-left: 1rem;
  }

  .home-container02 {
    height: fit-content;
    align-items: flex-start;
    width: 100%;
  }

  .team-exp-container span,
  .stage-container span,
  .industry-container span {
    font-size: 1.5rem;
  }

  .team-exp-container,
  .stage-container,
  .industry-container {
    width: 100% !important;
    padding: 0;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .home-container02 div:not(.separator) {
    width: auto;
    height: fit-content;
  }

  /* first .separator child of home-container02 */
  

  .home-container05 span,
  .home-container08 span,
  .home-container11 span {
    margin-right: 1rem;
  }

  .team-exp-text,
  .stage-text,
  .industry-text {
    width: 100% !important;
    margin: 0;
    padding-top: 1rem;
  }



  .home-our-investments {
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
  }

  .home-text35 {
    font-size: 2rem;
    padding-left: 1rem;
    margin-left: 0;
    margin-top: 2.5rem;
  }

  .home-text36 {
    margin-left: 0;
    padding-left: 1rem;
    font-size: 0.8rem !important;
    padding-right: 1rem;
  }

  /* .home-container15 {
    padding-top: 0;
  }

  .home-container15 span:not(.home-text35, .home-text36) {
    font-size: 1.5rem;
  }

  .home-container15 div:not(.invest-separator) {
    height: fit-content;
    margin: 0;
    padding-left: 1rem;
    padding-right: 1rem;
  } */

  .invest-separator {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .home-container17,
  .home-container20,
  .home-container23 {
    width: 100%;
    height: auto;
    margin-bottom: 0;
  }

  .home-container23 {
    margin-bottom: 2.5rem !important;
  }


  .home-text46 {
    font-size: 2rem;
    margin-left: 0;
  }

  .home-container25 {
    width: auto;
    height: auto;
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
    align-items: flex-start;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .home-container27,
  .home-container29,
  .home-container31 {
    width: auto;
    height: auto;
    flex-direction: column;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    align-items: flex-start;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0;
  }

  .home-text51,
  .home-text55,
  .home-text59 {
    font-size: 1.5rem;
    margin-left: 0;
    margin-bottom: 0.7rem;
  }

  .home-text50 {
    margin-bottom: 3rem;
    margin-top: 1rem;
  }

  .home-how-we-add-value {
    height: fit-content;
    padding-top: 1rem;
    padding-bottom: 2rem;
  }

  .home-how-we-add-value div:not(.separator) {
    width: auto;
    height: fit-content;
  }

  sup {
    font-size: 1rem;
  }

  .home-how-we-add-value .separator {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .home-container26 {
    margin-bottom: 0;
  }

  .how-we-add-val {
    display: none;
  }


  .home-get-updates {
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: #0E141C;
  }

  .home-container33 {
    width: auto;
    height: auto;
  }

  .home-text63 {
    margin: 0;
    padding-top: 3rem;
    padding-left: 1rem;
  }

  .home-text64 {
    margin: 0;
    padding-top: 2rem;
    padding-left: 1rem;
  }

  .home-textinput {
    margin: 0;
  }

  .home-logo1 {
    width: 100%;
    padding-top: 1rem;
  }

}

.wide-arrow {
  vertical-align: middle;
  display: inline-block;
  align-self: center;
}

[data-aos="move"] {
  transform: translate3d(0px, 0px, 0px) scale3d(0.97, 0.97, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  opacity: 0;
  transition: 2s opacity;
  transform-style: preserve-3d, opacity;
}
[data-aos="move"].aos-animate {
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  opacity: 1;
}