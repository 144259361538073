.portfolio-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}

.portfolio-text01 {
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  display: inline-block;
  position: relative;
}

.portfolio-text01:hover {
  color: #51B46B;
}

.portfolio-container01 {
  flex: 0 0 auto;
  width: 624px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.portfolio-text07 {
  font-size: 56px;
  font-style: normal;
  font-weight: 400;
}

.portfolio-text08 {
  margin-top: var(--dl-space-space-unit);
}

.grid-container {
  grid-column-gap: 0em;
  grid-row-gap: 0em;
  grid-template-rows: auto auto;
  /* grid-template-columns: 20% 20% 20% 20% 20%; */
  grid-template-columns: 20% 20% 20% 20%;
  grid-auto-columns: 1fr;
  grid-auto-flow: row;
  display: grid;
  margin-top: 1rem;
  margin-bottom: 1rem;
  justify-content: space-between;
}

.grid-item {
 /* round borders */
  border-radius: 50px;
  border: 1px solid white;
  padding-right: 30px;
  padding-left: 30px;
  margin-top: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
}

.grid-item img {
  filter: brightness(0);
  margin: auto;
  display: block;
  /* image-rendering: pixelated; */
  transition: 0s;
  width: 140px;
  height: 140px;
}

.grid-item:hover  {
  background-color: #51B46B;

  img {
  filter: brightness(0) invert(1);
  margin: auto;
  display: block;
}
}

.page-end {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

@media(max-width: 1000px) {

  .grid-container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .grid-item {
    width: 100%;
    height: auto;
    min-height: 232px;
    border-right: 0px;
    border-left: 0px;
  }

  .portfolio-container01 {
    margin:0;
  }

  .portfolio-logo1 {
    width: 100%;
  padding-top: 1rem;
  }

  .portfolio-text07 {
    font-size: 2rem;
  }
}

.flex-columns {
  display: flex;
  flex-direction: column;
}

.website-link {
  color: #1D1814;
  text-decoration: none;
  transition: 0.3s;
}

.website-link:hover {
  color: #51B46B;
}

/* .flex-container {
      font-family: "Roobert-Regular", sans-serif;
 margin: 40px 0px;
  border-top: 1px solid #1D1814;
  display: flex;
  padding-top: 8px;
  flex-direction: row;
  text-align: left;
    font-size: 13px;
    align-items: center;
    color: #1D1814;
} */
/* 
.flex-investment {
    line-height: 100%;
      font-family: "RecklessNeue-Thin", sans-serif;
  font-size: 52px;
  flex: 50%;
}

.flex-stage {
  padding: 10px;
  flex: 20%;
   align-self: start;
  
} */

/* .stage {
  border: 1px solid #0B674E;
  color: #0B674E;
  text-align: center;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 5px 20px 5px 20px;
}

.flex-link {
  padding: 10px;
  flex: 30%;
  text-align: right;
  text-transform: uppercase;
  letter-spacing: 2px;
  align-self: start;
} */

  
  @media screen and (max-width:950px) {
   
    
    .flex-investment {
     font-size: 31px;
  flex: 50%;
}
    .stage {
    display: none;
    }
    
    @media screen and (max-width:400px) {
    .flex-investment {
      font-family: "Roobert-Regular", sans-serif;
      font-size: 18px;
    }
    .flex-link {
      flex:50%;
    }
  }
}