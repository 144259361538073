.team-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}

.team-text01 {
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  display: inline;
  position: relative;
}

.team-text01:hover {
  color: #51B46B;
}

.team-container01 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.team-text07 {
  font-size: 56px;
  font-style: normal;
  font-weight: 400;
}

.team-text08 {
  margin-top: var(--dl-space-space-unit);
}

.team-separator {
  height: 1px;
  margin-top: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-gray-900);
  width: 100%;
}

.team {
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
  padding-bottom: 3rem;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
}

.team-info-container {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
}

.team-image {
  width: 18rem;
  height: 20rem;
  filter: grayscale(100%);
}

.team-member-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 18rem;
  padding-top: 1rem;
  align-items: center;
}

.team-member-name {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-right: 0.5rem;
  width: fit-content;
}

.team-name {
  font-size: 1.5rem;
}

.team-arrow-container {
  border: 1px solid var(--dl-color-gray-900);
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.1rem;
  transition: 0.3s all ease-in-out;

  &:hover {
    background-color: #51B46B;
    border: 0px;

    .team-arrow {
      stroke: white;
    }
  }
}

.team-arrow {
  width: 2rem;
  height: 2rem;
  stroke: var(--dl-color-gray-900);
}

.team {
  width: fit-content;
}

@media(min-width: 1700px) {
  .team-footer {
    flex-direction: column;
  }
}

@media(min-width: 1200px) and (max-width: 1699px) {
  .team-footer {
    flex-direction: column;
  }

  .team-product-container {
    margin-right: var(--dl-space-space-fourunits);
  }
}

@media(min-width: 1001px) and (max-width: 1199px) {
  .team-footer {
    flex-direction: column;
  }
}

@media(max-width: 1000px) {
  .team-footer {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }

  .team-container11 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }

  .team-logo1 {
    align-items: center;
  }

  .team-text25 {
    margin-top: var(--dl-space-space-twounits);
    text-align: center;
    margin-bottom: var(--dl-space-space-twounits);
  }

  .team-links-container {
    width: 100%;
    align-items: flex-start;
    flex-direction: row;
    justify-content: center;
  }

  .team-container12 {
    margin-right: var(--dl-space-space-fourunits);
  }

  .team-product-container {
    margin-right: var(--dl-space-space-fourunits);
  }

  /* .team-separator {
    width: 100%;
  } */
}

@media(max-width: 479px) {

  .team-text07 {
    font-size: 2rem;
  }

  .team-container01 {
    text-align: center;
  }

  .team {
    height: auto;
    margin-top: 2rem;
    flex-direction: column;
  }



  .team-separator {
    display: none;
  }

  .team-logo1 {
    width: 100%;
    padding-top: 1rem;
  }


}

[data-aos="move"] {
  transform: translate3d(0px, 0px, 0px) scale3d(0.97, 0.97, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  opacity: 0;
  transition: 2s opacity;
  transform-style: preserve-3d, opacity;
}
[data-aos="move"].aos-animate {
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  opacity: 1;
}