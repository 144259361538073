:root {
  --dl-color-gray-500: #595959;
  --dl-color-gray-700: #999999;
  --dl-color-gray-900: #D9D9D9;
  --dl-size-size-large: 144px;
  --dl-size-size-small: 48px;
  --dl-color-danger-300: #A22020;
  --dl-color-danger-500: #BF2626;
  --dl-color-danger-700: #E14747;
  --dl-color-gray-black: #000000;
  --dl-color-gray-white: #FFFFFF;
  --dl-size-size-medium: 96px;
  --dl-size-size-xlarge: 192px;
  --dl-size-size-xsmall: 16px;
  --dl-space-space-unit: 16px;
  --dl-color-primary-100: #003EB3;
  --dl-color-primary-300: #0074F0;
  --dl-color-primary-500: #14A9FF;
  --dl-color-primary-700: #85DCFF;
  --dl-color-success-300: #199033;
  --dl-color-success-500: #32A94C;
  --dl-color-success-700: #4CC366;
  --dl-size-size-xxlarge: 288px;
  --dl-size-size-maxwidth: 1400px;
  --dl-radius-radius-round: 50%;
  --dl-space-space-halfunit: 8px;
  --dl-space-space-sixunits: 96px;
  --dl-space-space-twounits: 32px;
  --dl-radius-radius-radius2: 2px;
  --dl-radius-radius-radius4: 4px;
  --dl-radius-radius-radius8: 8px;
  --dl-space-space-fiveunits: 80px;
  --dl-space-space-fourunits: 64px;
  --dl-space-space-threeunits: 48px;
  --dl-space-space-oneandhalfunits: 24px;
}

@font-face {
  font-family: 'Roc Grotesk';
  src: url('/Fonts/Kostic - Roc Grotesk Regular.otf') format('opentype');
  font-weight: normal;
}


* {
  font-family: "Roc Grotesk" !important;
}

.button {
  color: var(--dl-color-gray-black);
  display: inline-block;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.input {
  color: white;
  cursor: auto;
  padding: 0.5rem 1rem;
  background: transparent;
  border: none;
  width: 100%;
}
.input:focus {
  outline: none;
}


.list {
  width: 100%;
  margin: 1em 0px 1em 0px;
  display: block;
  padding: 0px 0px 0px 1.5rem;
  list-style-type: none;
  list-style-position: outside;
}
.list-item {
  display: list-item;
}
.navbar_text {
  transition: 0.3s;
}
.navbar_text:hover {
  color: #51B46B;
}
.Heading {
  font-size: 32px;
  font-family: "Roc Grotesk";
  font-weight: 700;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.Content {
  font-size: 16px;
  font-family: "Roc Grotesk";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}

.navbar-interactive {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.nav-text {
  color: #51B46B;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.nav-links {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.navlink {
  text-decoration: none;
  display: inline-block;
  position: relative;
  transition: 0.3s;
}
.navlink1 {
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
  display: inline-block;
  position: relative;
}
.textUnderline:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: -7px;
  left: 0;
  background-color: #51B46B;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
.textUnderline:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.navlink2 {
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
  display: inline-block;
  position: relative;
}
.navlink1:hover, .navlink:hover, .navlink2:hover {
  color: #51B46B;
}

.get-updates {
  width: 100%;
  height: auto;
  display: flex;
  /* padding-top: 2rem; */
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
  background-color: #0E141C;
  padding-bottom: 5rem
}

.get-updates-container {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}


.sub-text {
  color: var(--dl-color-gray-white);
  font-size: 32px;
  font-style: normal;
  margin-top: var(--dl-space-space-fourunits);
  font-weight: 400;
  margin-left: var(--dl-space-space-fourunits);
}

.sub-text01 {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-unit);
  margin-left: var(--dl-space-space-fourunits);
  max-width: 500px;
}

.subscribe {
  color: #fff;
  font-weight: 700;
}

.get-input {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 6.5rem;
  margin-right: 3rem;
  width: auto;
  border-bottom: 1px solid #646F7B;
}


.footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.footer-container34 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}

.footer-logo1 {
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex=start;
  flex-direction: column;
}
.footer-container35 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.footer-company-container {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 10rem;
}
.footer-separator {
  width: 100%;
  height: 1px;
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-gray-900);
}
.footer-container36 {
  display: flex;
  align-self: flex-end;
  align-items: flex-start;
}
.page-end {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.footer-logo1 {
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  flex-direction: column;
}

.footer-text {
  color: #000000;
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
}

.footer-text71 {
  margin-top: var(--dl-space-space-halfunit);
}

.footer-text:hover {
  color: #51B46B;
}

.footer-text91 {
  align-self: flex-start;
}

.footer-twitter,
.footer-link {
  filter: grayscale(100%);
  margin-right: var(--dl-space-space-unit);
}

.footer-twitter:hover,
.footer-link:hover {
  filter: grayscale(0%);
}

.footer-separator {
  height: 1px;
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-gray-900);
}

.footer-text70 {
  color: #51B46B;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}




@media(max-width: 1000px) {

  .footer {
    padding: var(--dl-space-space-unit);
  }

  .footer-container34 {
    align-items: center;
    flex-direction: column;
  }

  .footer-text71 {
    margin-top: 0;
    margin-bottom: 2rem;
  }

  .footer-company-container {
    margin-right: 3rem;
  }

  .footer-container35 {
    margin-right: 0px;
    margin-left: 0px;
  }

  .footer-text91 {
    text-align: center;
  }

  .desktop-menu {
    display: none;
  }
  .mobile-menu {
    display: flex;
  }
  .navbar-interactive {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }

  .get-updates {
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: #0E141C;
    padding-bottom: 2rem;
}

.get-updates-container {
  width: auto;
  height: auto;
}

.sub-text {
  margin: 0;
  padding-top: 3rem;
  padding-left: 1rem;
  font-weight: 400;
}

.sub-text01 {
  margin: 0;
  padding-top: 2rem;
  padding-left: 1rem;
}

.textinput {
  margin: 0;
  text-align: left;
}

.get-input {
  margin: 0;
  margin-top: 3rem;
  margin-left: 1rem;
}
}